<template>
	<div
		class="full-height flex-column "
	>
		<Search
			:search="search"
			:option="search_option"

			@change="getSearch(1)"
			@click="getSearch(1)"
			@toItem="toItem"
		>
			<select
				v-model="search.b_category"
				slot="add"
				class="box pa-5-10 mr-10"
				@change="getSearch(1)"
			>
				<option value="">카테고리</option>
				<option
					v-for="category in items_category"
					:key="'cate_' + category"
				>{{ category }}</option>
			</select>

			<select
				v-model="search.member_level"
				slot="add"
				class="pa-5-10 mr-10"
				@change="getSearch(1)"
			>
				<option value="">회원 등급</option>
				<option
					v-for="(code, index) in codes.M001.items"
					:key="'code_' + index"
					:value="code.code_index"
				>{{ code.code_name }}</option>
			</select>
		</Search>

		<div
			class="mt-10 pa-10 bg-white full-height flex-column overflow-y-auto"
		>
			<div class="text-right">
				<button
					class="btn-primary"
					@click="is_on_category = true"
				>카테고리</button>
			</div>
			<div
				v-if="items.length > 0"
				class="mt-10"
			>
				<table class="table table-even">
					<colgroup>
						<col width="80px" />
						<col width="120px" />
						<col width="auto" />
						<col width="180px" />

						<col width="120px" />
						<col width="150px" />
						<col width="180px" />
					</colgroup>
					<thead>
					<tr>
						<th><input type="checkbox" /></th>
						<th>카테고리</th>
						<th>제목</th>
						<th>내용</th>
						<th>회원 등급</th>
						<th>등록일시</th>
						<th>관리</th>
					</tr>
					</thead>
					<tbody>
					<template
						v-for="(item) in item_list"
					>
						<tr
							:key="'bbs_' + item.uid"
						>
							<td><input type="checkbox" /></td>
							<td>{{ item.b_category }}</td>
							<td class="td-left">{{ item.b_title }}</td>
							<td>
								<button
									class="box pa-5-10"
									@click="is_view == item.uid ? is_view = null : is_view = item.uid"
								>내용보기
									<v-icon
										v-if="is_view == item.uid "
										small
										class=" color-icon cursor-pointer"
									>mdi mdi-menu-up</v-icon>
									<v-icon
										v-else
										small
										class=" color-icon cursor-pointer"
									>mdi mdi-menu-down</v-icon>
								</button>
							</td>
							<td>
								<select
									v-model="item.is_level"
									slot="add"
									class="pa-5-10"
									@change="putBbs(item)"
								>
									<option
										v-for="(code, index) in codes.M001.items"
										:key="'code_' + index"
										:value="code.code_index"
									>{{ code.code_name }}</option>
								</select>
							</td>
							<td>{{ item.wDate }}</td>
							<td>
								<button
									class="btn-success pa-5-10 ml-10"

									@click="toDetail(item)"
								>수정</button>
								<button
									class="btn-danger pa-5-10 ml-10"
									@click="isDelete(item)"
								>삭제</button>
							</td>
						</tr>
						<tr
							v-if="is_view == item.uid"
							:key="'bbs_contents_' + item.uid"
						>
							<td class="text-right"><v-icon class="size-px-20 color-icon ">mdi-subdirectory-arrow-right</v-icon></td>
							<td colspan="7" class="td-left  ">
								<Viewer
									v-if="item.b_contents"
									:initialValue="item.b_contents"
								/>
							</td>
						</tr>
					</template>
					</tbody>
				</table>

				<Pagination
					:program="program"
					:align="'center'"
					:options="search"

					@click="getSearch"
				></Pagination>
			</div>

			<Empty
				v-else
			></Empty>

		</div>

		<Modal
			:is_modal="is_modal"
			:option="modal_option"

			@close="doClear"
		>
			<template
				slot="modal-bottom"
			>
				<button
					class="flex-1 pa-10 bg-red color-eee"
					@click="doDelete"
				>삭제</button>
				<button
					class="flex-1 pa-10 bg-gray-light color-333"
					@click="doClear"
				>취소</button>
			</template>
		</Modal>

		<PopupLayer
			v-if="is_on_category"

			@click="postCategory"
			@cancel="is_on_category = false"
			@close="is_on_category = false"

			:width="'480px'"
		>
			<template
				v-slot:body
			>
				<div
					class=" flex-column bg-white"
				>
					<div class="justify-space-between under-line mb-10 pa-10">
						<h6>FAQ 카테고리</h6>
						<button
							@click="is_on_category = false"
						><v-icon>mdi mdi-close-circle</v-icon></button>
					</div>

					<div class="pa-10">
						<textarea
							v-model="board_category"
							class="box"
							placeholder="카테고리를 입력하세요 엔터로 구분합니다"
							rows="8"
						></textarea>
					</div>

					<div class=" text-center pa-10">
						<button
							@click="postCategory"
							class="btn-primary"
						> 저장 </button>
					</div>
				</div>

			</template>
		</PopupLayer>
	</div>
</template>

<script>
import Search from "@/view/Layout/Search";
import Pagination from "@/components/Pagination";

import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import { Viewer } from "@toast-ui/vue-editor";
import Modal from "@/components/Modal";
import Empty from "@/view/Layout/Empty";
import PopupLayer from "../Layout/PopupLayer";

export default {
	name: 'CustomerCenterFaqList'
	, components: {PopupLayer, Empty, Modal, Pagination, Search,Viewer}
	, props: ['user', 'codes']
	,data: function(){
		return {
			program: {
				name: 'FAQ'
				,top: true
				,title: true
				,bottom: false
			}
			,search:{
				b_code: 'b_faq'
				, page: 1
				, list_cnt: 10
				, tCnt: 0
				, search_type: ''
				, b_answer: ''
				, b_category: ''
				, member_level: ''
			}
			,search_option: {
				is_excel: false
				,is_item: true
				,is_cnt: true
				,sDate: false
				,eDate: false
				,cnt: 0
				,tCnt: 0
				,search_type: [
					{ name: '제목', column: 'title'}
					,{ name: '아이디', column: 'm_id'}
					,{ name: '이름', column: 'm_name'}
				]
			}
			,items: [

			]
			,is_view: null
			,is_modal: false
			,modal_option: {
				top: true
				,title: '게시글 삭제'
				,content: '해당 게시글을 삭제하시겠습니까?'
				,bottom: true
				,width: '380px'
				, slot_bottom: true
			}
			, is_on_category: false
			, board_category: ''
		}
	}
	,computed: {

		item_list: function(){
			return this.items.filter(function(item){
				if(item.b_answer){
					item.is_answer_color = 'green'
					item.is_answer_name = '답변 완료'
				}else{
					item.is_answer_color = ''
					item.is_answer_name = '답변 대기'
				}
				return item
			})
		}
		, items_category: function(){
			let t = []

			t = this.board_category?.split("\r\n")

			return t
		}
	}
	,methods: {
		getData: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getBbsFaqList'
					,data: this.search
				})
				if(result.success){
					// console.log(result.data.result)
					this.items = result.data.result
					this.$set(this.search, 'total_count', result.data.tCnt)
					this.$set(this.search_option, 'tCnt', result.data.tCnt)
					this.$set(this.search_option, 'cnt', result.data.cnt)
					this.board_category = result.data.category
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,toItem: function(){
			this.$router.push({ name: 'BbsItem', params: { b_code: this.search.b_code}})
		}
		,toDetail: function(item){
			if(this.user.role == this.codes.type_code_admin || this.user.role == this.codes.type_code_agency){
				this.$router.push({ name: 'BbsDetail', params: { b_code: this.search.b_code, bbs_uid: item.uid }})
			}else{
				this.$router.push({ name: 'BbsView', params: { b_code: this.search.b_code, bbs_uid: item.uid }})
			}
		}
		,doClear: function(){
			this.is_modal = false
			this.item_delete = null
		}
		,doDelete: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postBbsDelete'
					,data: {
						ATOKEN: this.TOKEN
						,bbs_uid: this.item_delete.uid
						,b_code: this.$route.params.b_code
					}
				})

				if(result.success){
					this.doClear()
					await this.getData()
					this.$bus.$emit('notify', { type: 'success', message: result.message})
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,isDelete: function(item){
			this.is_modal = true
			this.item_delete = item
		}
		, postCategory: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postBoardCategory'
					,data: {
						b_code: this.search.b_code
						, category: this.board_category
					}
					, version: 'v2'
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					this.is_on_category = false
					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, putBbs: async function(item){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/putBbs'
					,data: {
						uid: item.uid
						, is_level: item.is_level
					}
				})

				if(result.success){

					this.$bus.$emit('notify', { type: 'success', message: result.message})
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getSearch: function(page){
			this.search.page = page
			this.getData()
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>